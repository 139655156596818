import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FaBars, FaUser } from "react-icons/fa";
import { callApi } from "../../Utils/api";
import { useParams } from "react-router-dom";
import MobileNav from "./MobileNav";
import LogoComponent from "../../Components/Switch/Logo";
import SwitchDarkMode from "../../Components/Switch/SwitchMode";
import { ThemeProvider } from "../../Components/Switch/MainToggle";
import { InfinitySpin } from "react-loader-spinner";

const Header = () => {
  const params = useParams();
  const [isToggle, setToggle] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  const handleToggle = () => {
    setToggle(!isToggle);
  };

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoad(true);
    callApi(`event-category`, {}, "get")
      .then((res) => {
        if (res.data.status === 200) {
          setData(res?.data?.result);
          setIsLoad(false);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
       
        setLoading(false);
      });
  };

  return (
    <ThemeProvider>
      <section className="shadow-orange ">
        <nav class=" container mx-auto  md:pt-4 md:py-2 p-4  md:px-24">
          <div
            className={`flex items-center justify-between dark:text-black text-black`}
          >
            <LogoComponent  />
            <div className="hidden  flex-grow px-auto gap-10 capitalize font-medium dark:text-white text-[#7A7A7A]  md:flex items-center justify-end mr-10">
              {!loading && data.length > 0
                ? data.map((item) => (
                    <Link
                      key={item.id}
                      to={"/events" + "/" + item.category_slug + "/" + item.id}
                    >
                      <span
                        className={`cursor-pointer ${
                          item.id == params.id
                            ? "dark:text-skyBlue-300 text-skyBlue-300 "
                            : "dark:text-white"
                        } hover:text-skyBlue-400  dark:hover:text-skyBlue-400`}
                      >
                        {item.name}
                      </span>
                    </Link>
                  ))
                : null}
            </div>
            <div className="flex gap-2 items-center">
              {localStorage.getItem("token") ? (
                <>
                  <Link to="/Myaccount/dashboard">
                    <div
                      className={`
                      cursor-pointer hover:border-[1px] border-[#374151] w-8 h-8 md:w-12 md:h-12 flex items-center bg-[#f4f5f6] hover:bg-transparent justify-center rounded-full dark:bg-[#0e1010] dark:hover:bg-transparent
                      `}
                    >
                      <FaUser className="md:w-6 md:h-6 w-4 h-4 rounded-full text-[#374151] dark:text-[#667387]" />
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/login">
                    <button
                      className={`bg-skyBlue-300 hidden md:block font-semibold w-32 text-white py-2 px-4 rounded-md  ${
                        hoveredLink === "Sign In"
                          ? "text-[#00C4FF]"
                          : "text-[#00C4FF]"
                      }`}
                      onMouseEnter={() => handleMouseEnter("Sign In")}
                      onMouseLeave={handleMouseLeave}
                    >
                      Sign In
                    </button>
                  </Link>
                </>
              )}
              <SwitchDarkMode />
              <div className="block md:hidden">
                <MobileNav onClick={handleToggle} />
              </div>
            </div>
          </div>
        </nav>
        {isLoad && (
          <div className="bg-overlay">
            <InfinitySpin color="#00c9ff" />
          </div>
        )}
      </section>
    </ThemeProvider>
  );
};

export default Header;
