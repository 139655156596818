import React, { useState } from "react";
import Layout from "../../Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import PickupDropdown from "../../Components/Dropdown/PickupDropdown";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactHtmlParser from "react-html-parser";
import { callApi } from "../../Utils/api";
import { useEffect } from "react";

import { MdOutlineDirectionsBus } from "react-icons/md";

const ProductDetails = () => {
  const location = useLocation();
  const getData = location.state;
  let navigate = useNavigate();
  let { name } = useParams();
  const [eventDetails, setEventDetails] = useState("");
  const [isLoad, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedCounties, setSelectedCounties] = useState(" ");
  const [selectedPickup, setSelectedPickup] = useState(" ");
  const [allCounties, setAllCounties] = useState("");
  const [filteredBusLists, setFilteredBusLists] = useState([]);


  //Get Event Details
  const getEventDetail = () => {
    setLoad(false);
    callApi(`event-detail/${name}`, {}, "get").then((res) => {
      if (res?.data) {
        setEventDetails(res?.data?.result);
        getCounties(res?.data?.result?.id);
        setSelectedDate(
          getData
            ? getData?.booking?.date
            : res?.data?.result?.available_days[0]
        );
        // busesByEvent(res?.data?.result?.id)
      }
      setLoading(false);
    });
  };

  //Get All Countries
  const getCounties = (eventID) => {
    // setLoading(true);
    callApi(`/counties?event_id=${eventID}`, {}, "get")
      .then((res) => {
        if (res?.data?.data) {
          setAllCounties(res?.data?.data);
          setSelectedCounties(
            getData ? getData?.booking?.selectedCounty : res?.data?.data[0]
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCounties && selectedDate) {
      busesByEvent();
    }
  }, [selectedDate, selectedCounties]);

  //Get All Buses
  const busesByEvent = (
    id = eventDetails.id,
    county = selectedCounties,
    date = selectedDate
  ) => {
    const apiEndpoint = `get-seats-by-event/${id}?county=${county?.id}&search_date=${date}`;
    setLoading(true);
    callApi(apiEndpoint, {}, "get")
      .then((res) => {
        if (res?.data?.data && res?.data?.data.length > 0) {
          const arr = res?.data?.data.map((item) => {
            return {
              ...item,
              bookingDetail: {
                event_id: id,
                name: eventDetails?.name,
                image: eventDetails?.image,
                assigned_seats: item.assigned_seats,
                date: date,
                county_Id: "",
                county_name: "",
                pickup_id: "",
                pickup_name: "",
                town: "",
                landmark: "",
                price: 0,
                qty: 1,
              },
            };
          });
          setFilteredBusLists(arr);
          setLoading(false);
        } else {
          setLoading(false);
          setFilteredBusLists([]);
        }
      })
      .catch((err) => {
        setFilteredBusLists(err);
      });
  };

  useEffect(() => {
    getEventDetail();
  }, []);

  const handleChange = (name, value, index) => {
    const arr = [...filteredBusLists];
    arr[index].bookingDetail[name] = value;
    if (name === "price") {
      arr[index].bookingDetail.totaLPrice =
        Number(arr[index].bookingDetail.qty) * Number(value);
    }

    setFilteredBusLists(arr);
  };

  let decNum = (index) => {
    const arr = [...filteredBusLists];
    if (arr[index].bookingDetail.qty > 1) {
      arr[index].bookingDetail.qty = arr[index].bookingDetail.qty - 1;
      arr[index].bookingDetail.totaLPrice =
        arr[index].bookingDetail.qty * Number(arr[index].bookingDetail.price);
      setFilteredBusLists(arr);
    }
  };

  let incNum = (index) => {
    const arr = [...filteredBusLists];
    if (arr[index].bookingDetail.qty < arr[index].assigned_seats) {
      arr[index].bookingDetail.qty = arr[index].bookingDetail.qty + 1;
      arr[index].bookingDetail.totaLPrice =
        arr[index].bookingDetail.qty * Number(arr[index].bookingDetail.price);
      setFilteredBusLists(arr);
    }
  };

//   const handleBookNow = (index) => {
//     const arr = [...filteredBusLists];
//     if (
//       arr[index].bookingDetail.pickup_id === "" ||
//       arr[index].bookingDetail.pickup_name === "" ||
//       arr[index].bookingDetail.totaLPrice === ""
//     ) {
//       toast.warn("Please select the Options");
//       setLoad(false);
//     } else {
//       arr[index].bookingDetail.date = selectedDate;
//       arr[index].bookingDetail.county_Id = selectedCounties?.id;
//       arr[index].bookingDetail.county_name = selectedCounties?.name;
//       var arrCart = [arr[index].bookingDetail];
//       localStorage.setItem("cartItems", JSON.stringify(arrCart));
// // filterbuslist = attached_id

//       console.log(filteredBusLists , 'arrCart')
//       return
//       navigate("/checkout", {
//         state: {
//           booking: arr[index].bookingDetail,
//           productdetails: eventDetails,
//         },
//       })
//       // setTimeout(() => {
//       //   setLoad(false);
//       //   const isAuth = localStorage.getItem("token");
//       //   isAuth
//       //     ? navigate("/checkout", {
//       //         state: {
//       //           booking: arr[index].bookingDetail,
//       //           productdetails: eventDetails,
//       //         },
//       //       })
//       //     : navigate("/login", {
//       //         state: {
//       //           booking: arr[index].bookingDetail,
//       //           productdetails: eventDetails,
//       //         },
//       //       });
//       // }, 500);
//     }
//   };

const handleBookNow = (index) => {
  const arr = [...filteredBusLists];
  const selectedBus = arr[index];
  
  if (
    selectedBus.bookingDetail.pickup_id === "" ||
    selectedBus.bookingDetail.pickup_name === "" ||
    selectedBus.bookingDetail.totaLPrice === ""
  ) {
    toast.warn("Please select the Options");
    setLoad(false);
  } else {
    selectedBus.bookingDetail.date = selectedDate;
    selectedBus.bookingDetail.county_Id = selectedCounties?.id;
    selectedBus.bookingDetail.county_name = selectedCounties?.name;

    // Adding attached_id to bookingDetail
    selectedBus.bookingDetail.attached_id = selectedBus.attached_id;

    // Now create arrCart with updated bookingDetail
    const arrCart = [selectedBus.bookingDetail];
    localStorage.setItem("cartItems", JSON.stringify(arrCart))

    navigate("/checkout", {
      state: {
        booking: selectedBus.bookingDetail,
        productdetails: eventDetails,
      },
    });
  }
};



  return (
    <React.Fragment>
      <div className=" md:my-4 ">
        <section className="">
          {/* <div className=" row p-4 md:p-10 rounded-2xl flex flex-wrap justify-start items-center dark:bg-[#2B2B2B] bg-white">
            <div className="col-md-3 flex justify-center md:justify-start mb-5 md:mb-0 md:mr-10">
              <img
                src={eventDetails?.image}
                alt={eventDetails?.name}
                className="rounded-2xl w-full max-w-[400px] h-auto"
              />
            </div>
            <div className="col-sm-6 md:pl-14">
              <h2 className="font-semibold dark:text-[#D8DBDE] text-[#111827] text-3xl">
                {eventDetails?.name}
              </h2>
              <div className="mt-5">
                <span className="font-semibold dark:text-[#E5E7EB] text-[#111827] text-2xl">
                  € {eventDetails?.price}
                </span>
                <div className="mt-5">
                  {eventDetails && eventDetails.shortdesc && (
                    <div className="space-y-3 font-light dark:text-[#c9ccd7] text-[#3f4858]">
                      {ReactHtmlParser(
                        eventDetails.shortdesc.replace(/[.!]/g, ". <br/>")
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          {/* </div> */}

          <div className=" md:m-4  booking-wrapper row p-4 md:p-8 rounded-2xl flex  justify-start dark:bg-[#2B2B2B] bg-white">
            <div className="flex  justify-start ">
              <img
                src={eventDetails?.image}
                alt={eventDetails?.name}
                className="rounded-xl object-contain "
              />
            </div>
            <div className="booking-info  md:pl-14 w-full">
              <h2 className="font-semibold dark:text-[#D8DBDE] text-[rgb(17,24,39)] text-xl md:text-3xl">
                {eventDetails?.name}
              </h2>
              <div className="mt-5">
                <span className="font-semibold dark:text-[#E5E7EB] text-[#111827] text-xl md:text-2xl">
                  € {eventDetails?.price}
                </span>

                <div className="mt-5  ">
                  {eventDetails && eventDetails.shortdesc && (
                    <div className="space-y-3 font-light dark:text-[#c9ccd7] text-[#3f4858]">
                      {ReactHtmlParser(
                        eventDetails.shortdesc.replace(/[.!]/g, ". <br/>")
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="date-and-county md:px-4 my-2 ">
          <h1 className="text-2xl my-6 font-semibold font-display  text-[#303030] dark:text-neutral-300">
            Book Your Bus Seat
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
            <aside className="shadow-orange date-section dark:bg-[#2B2B2B] bg-white  rounded-2xl p-4 date-button gap-2">
              <h1 className="mb-2 font-semibold text-[#111827] pb-1 flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 text-skyBlue-300"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className=" dark:text-[#E5E7EB] ">
                  {" "}
                  Event Date :{" "}
                </span>
              </h1>
              <div className="flex flex-wrap md:gap-4 gap-2">
                {eventDetails?.available_days?.map((date, index) => {
                  const formatDate = (dateString) => {
                    const options = {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    };
                    return new Date(dateString).toLocaleDateString(
                      "en-US",
                      options
                    );
                  };
                  return (
                    <div
                      key={index}
                      className="customRadio col-sm-2 gap-5 md:mt-0 mt-2 flex col-6 "
                    >
                      <button>
                        <input
                          type="radio"
                          id={date}
                          name="date"
                          value={selectedDate}
                          className="hidden"
                          onChange={() => {
                            setSelectedDate(date);
                            busesByEvent(
                              eventDetails.id,
                              selectedCounties,
                              date
                            );
                          }}
                        />
                        <label
                          htmlFor={date}
                          className={`customRadioButton rounded-md mx-1 text-sm 
                           py-[6px]  cursor-pointer px-5 border-[1px]  ${selectedDate === date
                              ? "  border-skyBlue-300 text-white bg-skyBlue-200 font-medium  "
                              : " dark:text-gray-300 border-gray-300 dark:bg-[#2B2B2B] bg-white  font-medium text-[#8f8f8f] "
                            }`}
                        >
                          {formatDate(date)}
                        </label>
                      </button>
                    </div>
                  );
                })}
              </div>
            </aside>

            <aside className="shadow-orange county-section dark:bg-[#2B2B2B] bg-white  rounded-2xl p-4 ">
              <h1 className="mb-1 font-semibold text-[#111827] pb-1 flex  items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 text-skyBlue-300"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <label className="dark:text-white">
                  County you wish to Travel from:
                </label>
              </h1>
              <div className="flex flex-wrap md:gap-1 gap-2">
                {allCounties && allCounties.length > 0
                  ? allCounties?.map((county) => {
                    return (
                      <button
                        onClick={() => {
                          setSelectedCounties(county);
                          busesByEvent(
                            eventDetails?.id,
                            county,
                            selectedDate
                          );
                        }}
                        className={` customRadioButton rounded-md mx-1 text-sm 
                          py-[6px]  cursor-pointer px-5 border-[1px]                      ${selectedCounties?.id == county?.id
                            ? "  text-white bg-skyBlue-200 border-skyBlue-300 font-medium"
                            : " border-gray-300  dark:text-gray-300  dark:bg-[#2B2B2B] bg-white font-medium text-[#8f8f8f]"
                          }
                          `}
                      >
                        {" "}
                        {county.name}
                      </button>
                    );
                  })
                  : null}
              </div>
            </aside>
          </div>
        </section>

        <section
          className="bus-section lg:mx-3 my-4 rounded-2xl mb-2 "
          id="scolltoticket"
        >
          {loading ? (
            <>
              <Skeleton height={30} count={5} />
            </>
          ) : (
            <>
              {filteredBusLists && filteredBusLists?.length > 0 ? (
                filteredBusLists?.map((data, index) => {
                  return (
                    <>
                      <div className="grid grid-cols-1 shadow-orange rounded-2xl dark:bg-[#2B2B2B] bg-white  md:grid-cols-4 md:px-8 my-4 ">
                        <div className="col-span-1 gap-4 flex p-4 min-w-xs justify-center md:justify-start pb-4 border-opacity-50   dark:border-r-gray-300 items-center border-r-[1px] border-r-gray-300">
                          <img class="object-cover w-12 h-12 rounded-lg" src={data.avatar} alt={data?.company_name} />
                          <div className="text-start">
                            <h1 className="text-xl text-[#111827] dark:text-white font-semibold">
                              {data?.company_name}
                            </h1>
                            <p className="flex md:justify-start items-center text-[#111827] dark:text-skyBlue-300">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                className="text-skyBlue-300"
                              >
                                <path
                                  d="M7.00033 1.16663C3.78033 1.16663 1.16699 3.77996 1.16699 6.99996C1.16699 10.22 3.78033 12.8333 7.00033 12.8333C10.2203 12.8333 12.8337 10.22 12.8337 6.99996C12.8337 3.77996 10.2203 1.16663 7.00033 1.16663ZM7.00033 5.10413C6.76116 5.10413 6.56283 4.90579 6.56283 4.66663C6.56283 4.42746 6.76116 4.22913 7.00033 4.22913C8.52866 4.22913 9.77116 5.47163 9.77116 6.99996C9.77116 8.52829 8.52866 9.77079 7.00033 9.77079C6.76116 9.77079 6.56283 9.57246 6.56283 9.33329C6.56283 9.09413 6.76116 8.89579 7.00033 8.89579C8.04449 8.89579 8.89616 8.04413 8.89616 6.99996C8.89616 5.95579 8.04449 5.10413 7.00033 5.10413ZM7.00033 11.5208C4.50949 11.5208 2.47949 9.49079 2.47949 6.99996C2.47949 6.76079 2.67783 6.56246 2.91699 6.56246C3.15616 6.56246 3.35449 6.76079 3.35449 6.99996C3.35449 9.01246 4.98783 10.6458 7.00033 10.6458C9.01283 10.6458 10.6462 9.01246 10.6462 6.99996C10.6462 4.98746 9.01283 3.35413 7.00033 3.35413C6.76116 3.35413 6.56283 3.15579 6.56283 2.91663C6.56283 2.67746 6.76116 2.47913 7.00033 2.47913C9.49116 2.47913 11.5212 4.50913 11.5212 6.99996C11.5212 9.49079 9.49116 11.5208 7.00033 11.5208Z"
                                  fill="url(#paint0_linear_457_42)"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_457_42"
                                    x1="7.96225"
                                    y1="6.03803"
                                    x2="11.7915"
                                    y2="2.20822"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#00c4ff" />
                                    <stop offset="1" stop-color="#00d5ff" />
                                  </linearGradient>
                                </defs>
                              </svg>
                              <span className="px-1 text-sm text-[#111827] dark:text-white ">
                                Price
                                <span className="pl-1"> {data ? data?.prices : 0}€</span>
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className=" col-span-2 px-5 lg:px-12 md:py-6  border-opacity-50  dark:border-r-gray-300  border-r-[1px] border-r-gray-300">
                          <PickupDropdown
                            label="Pick up Points"
                            placeholder='Select pick up point'
                            name="pickup"
                            className="mb-2 "
                            options={data?.pickup_points}
                            handlePickupChange={(e) => {
                              Object.keys(e).map((item) => {
                                handleChange(item, e[item], index);
                              });
                            }}
                          />
                          <div className="md:flex py-2 flex-wrap justify-evenly ">
                            <div className="md:block flex justify-between my-2 md:my-0">
                              <h1 className=" flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-4 h-4 mr-2 text-skyBlue-300"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <span className="dark:text-white text-[#111827]">
                                  {" "}
                                  Time
                                </span>
                              </h1>
                              <p className="dark:text-white text-[#111827] mt-2 px-4">
                                {" "}
                                {data.bookingDetail?.time
                                  ? data.bookingDetail?.time
                                  : "--|--"}
                              </p>
                            </div>

                            <div className=" border-opacity-50   dark:border-r-gray-300  border-r-[1px] border-r-gray-300">
                              {" "}
                            </div>
                            <div className="md:block text-[#111827] dark:text-white flex justify-between ">
                              <h1 className=" flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  className="mr-2"
                                >
                                  <path
                                    d="M7.00033 1.16663C3.78033 1.16663 1.16699 3.77996 1.16699 6.99996C1.16699 10.22 3.78033 12.8333 7.00033 12.8333C10.2203 12.8333 12.8337 10.22 12.8337 6.99996C12.8337 3.77996 10.2203 1.16663 7.00033 1.16663ZM7.00033 5.10413C6.76116 5.10413 6.56283 4.90579 6.56283 4.66663C6.56283 4.42746 6.76116 4.22913 7.00033 4.22913C8.52866 4.22913 9.77116 5.47163 9.77116 6.99996C9.77116 8.52829 8.52866 9.77079 7.00033 9.77079C6.76116 9.77079 6.56283 9.57246 6.56283 9.33329C6.56283 9.09413 6.76116 8.89579 7.00033 8.89579C8.04449 8.89579 8.89616 8.04413 8.89616 6.99996C8.89616 5.95579 8.04449 5.10413 7.00033 5.10413ZM7.00033 11.5208C4.50949 11.5208 2.47949 9.49079 2.47949 6.99996C2.47949 6.76079 2.67783 6.56246 2.91699 6.56246C3.15616 6.56246 3.35449 6.76079 3.35449 6.99996C3.35449 9.01246 4.98783 10.6458 7.00033 10.6458C9.01283 10.6458 10.6462 9.01246 10.6462 6.99996C10.6462 4.98746 9.01283 3.35413 7.00033 3.35413C6.76116 3.35413 6.56283 3.15579 6.56283 2.91663C6.56283 2.67746 6.76116 2.47913 7.00033 2.47913C9.49116 2.47913 11.5212 4.50913 11.5212 6.99996C11.5212 9.49079 9.49116 11.5208 7.00033 11.5208Z"
                                    fill="url(#paint0_linear_457_42)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_457_42"
                                      x1="7.96225"
                                      y1="6.03803"
                                      x2="11.7915"
                                      y2="2.20822"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#F58412" />
                                      <stop offset="1" stop-color="#F8A60D" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span className=""> Price Per Seat</span>
                              </h1>
                              <p className="mt-2 px-10">
                                {"€"}
                                {data.bookingDetail?.price}
                              </p>
                            </div>
                            <div className=" border-opacity-50   dark:border-r-gray-300  border-r-[1px] border-r-gray-300">
                              {" "}
                            </div>

                            <div className="grid px-2 md:grid-cols-1 place-content-center place-items-center text-center gap-2 text-sm text-neutral-700  dark:text-white ">
                              <h1 className=" flex items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  className="mr-2"
                                >
                                  <path
                                    d="M7.00033 1.16663C3.78033 1.16663 1.16699 3.77996 1.16699 6.99996C1.16699 10.22 3.78033 12.8333 7.00033 12.8333C10.2203 12.8333 12.8337 10.22 12.8337 6.99996C12.8337 3.77996 10.2203 1.16663 7.00033 1.16663ZM7.00033 5.10413C6.76116 5.10413 6.56283 4.90579 6.56283 4.66663C6.56283 4.42746 6.76116 4.22913 7.00033 4.22913C8.52866 4.22913 9.77116 5.47163 9.77116 6.99996C9.77116 8.52829 8.52866 9.77079 7.00033 9.77079C6.76116 9.77079 6.56283 9.57246 6.56283 9.33329C6.56283 9.09413 6.76116 8.89579 7.00033 8.89579C8.04449 8.89579 8.89616 8.04413 8.89616 6.99996C8.89616 5.95579 8.04449 5.10413 7.00033 5.10413ZM7.00033 11.5208C4.50949 11.5208 2.47949 9.49079 2.47949 6.99996C2.47949 6.76079 2.67783 6.56246 2.91699 6.56246C3.15616 6.56246 3.35449 6.76079 3.35449 6.99996C3.35449 9.01246 4.98783 10.6458 7.00033 10.6458C9.01283 10.6458 10.6462 9.01246 10.6462 6.99996C10.6462 4.98746 9.01283 3.35413 7.00033 3.35413C6.76116 3.35413 6.56283 3.15579 6.56283 2.91663C6.56283 2.67746 6.76116 2.47913 7.00033 2.47913C9.49116 2.47913 11.5212 4.50913 11.5212 6.99996C11.5212 9.49079 9.49116 11.5208 7.00033 11.5208Z"
                                    fill="url(#paint0_linear_457_42)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_457_42"
                                      x1="7.96225"
                                      y1="6.03803"
                                      x2="11.7915"
                                      y2="2.20822"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stop-color="#00c4ff" />
                                      <stop offset="1" stop-color="#00d5ff" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                                <span> Add Quantity</span>
                              </h1>
                              <div className="flex items-center justify-center">
                                <div
                                  className={`flex cursor-pointer items-center justify-center px-[6px] rounded-[4px] w-7 h-7 pb-1 text-white  dark:text-white text-xl ${data.bookingDetail.qty <= 1
                                    ? "bg-gray-500"
                                    : " bg-gradient-to-br from-[#26e2ff] via-[#0aadde] to-[#00607d] dark dark:bg-[#2B2B2B]:bg-white"
                                    }`}
                                  onClick={() => decNum(index)}
                                >
                                  -
                                </div>
                                <input
                                  type="number"
                                  name="qty"
                                  className="px-2 text-center pt-0 md:pr-0 border-none w-16 mx-auto  text-[#111827] dark:text-white dark:bg-[#2B2B2B]   "
                                  value={
                                    Number(data?.assigned_seats) < 1
                                      ? 0
                                      : data.bookingDetail?.qty
                                  }
                                  disabled
                                />
                                <div
                                  className={`flex items-center justify-center px-[6px] rounded-[4px] cursor-pointer w-7 h-7 pb-1 text-white  dark:text-white text-xl  ${data.bookingDetail.qty >=
                                    data.assigned_seats
                                    ? "bg-gray-500"
                                    : "bg-gradient-to-br from-[#26e2ff] via-[#0aadde] to-[#00607d] dark dark:bg-[#2B2B2B]:bg-white"
                                    }`}
                                  type="span"
                                  onClick={() => incNum(index)}
                                  disabled={!data?.bookingDetail?.totaLPrice}
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex justify-center items-center  ">
                          <div className="text-center">
                            <p className="text-black mb-2 dark:text-white text-2xl font-semibold">
                              {" "}
                              €{data.bookingDetail.totaLPrice}
                            </p>

                            <ButtonPrimary
                              onClick={() => handleBookNow(index)}
                              className="button w-full rounded-md px-4 py-2"
                            >
                              Book Now
                            </ButtonPrimary>

                            <div className="text-sm my-2">
                              <p className="text-[#767676] dark:text-skyBlue-300">
                                {Number(data?.assigned_seats) < 1 ? (
                                  <>No Seats Available</>
                                ) : (
                                  <>
                                    <span className="dark:text-white">
                                      {" "}
                                      Available Seats:{" "}
                                    </span>
                                    {Number(data?.assigned_seats) -
                                      Number(data.bookingDetail?.qty)}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ToastContainer />
                    </>
                  );
                })
              ) : (
                <>
                  <div className="flex flex-col h-[16vh] md:h-[30vh] justify-center items-center gap-2">
                    <MdOutlineDirectionsBus className="h-12 w-12 text-3xl text-gray-500 dark:text-gray-400" />
                    <div className="text-2xl font-bold">No Bus available</div>
                    <p className="max-w-[600px] text-gray-500 text-center dark:text-gray-400">
                      There are no buses available in the selected filter at
                      this time.
                    </p>
                  </div>
                </>
              )}
            </>

          )}
        </section>
      </div>

      {isLoad && (
        <div className="bg-overlay">
          <InfinitySpin color="#00c9ff" />
        </div>
      )}
    </React.Fragment>
  );
};

export default Layout(ProductDetails);
