import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Success from "../../images/Icon.png";
import ButtonPrimary from "../../Components/Buttons/ButtonPrimary";
import Layout from "../../Layout";
import { IoMdArrowBack } from "react-icons/io";
import NotFound from "../NotFound/NotFound";
const PayDone = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { id } = useParams();
  const productDetails = location.state;
const getToken = localStorage.getItem('token')

  console.log(getToken ,'productDetails')
  return (
    <React.Fragment>
      {id === productDetails?.BookedItems?.order_id ? (
        <section className="pb-6 home-Section h-screen ">
          <div className="container flex justify-center items-center  md:relative mx-auto">
            <div className="md:w-2/3 mt-4 m-4 p-4 dark:bg-[#3B3B3B] bg-white rounded-lg">
              <div className="w-full flex md:p-0  flex-col rounded-2xl space-y-8 px-0  xl:p-8">
                <div className="flex justify-center items-center">
                  <img src={Success} className="w-28" alt="" />
                </div>

                {/* <div className="border-b border-skyBlue-200 dark:border-skyBlue-700"></div> */}

                {/* ------------------------ */}
                <div className="">
                  <h1 className="my-6 text-2xl pb-6 border-b-gray-300 border-b text-[#111827]  dark:text-white  font-semibold text-center">
                    Thank you for booking our Event Bus.
                  </h1>

                  <div className="flex  justify-center items-center text-center">
                    <p className="m-0 text-md text-gray-400 pt-2 dark:text-skyBlue-300  font-normal ">
                      Your reservation for our Event Bus has been successfully
                      placed.
                    </p>
                  </div>
                  <div className="flex  justify-center items-center text-center">
                    <p className=" m-0  text-gray-400 text-md mb-6 dark:text-skyBlue-300  font-normal ">
                      You should expect to receive a confirmation email shortly.
                    </p>
                  </div>
                </div>
                <div className="flex font-normal mb-7 text-gray-600 dark:text-white justify-center  md:text-xl ">
                  Order ID :-
                  <span className=" hover:text-skyBlue-700 text-skyBlue-300 ">
                    {" "}
                    {/* // <Link to="/Myaccount/booking"> */}
                      {productDetails?.BookedItems?.order_id}
                    {/* </Link> */}
                  </span>
                </div>
                {
                  getToken && (

                     <div className="my-5 w-full">
                    <Link to="/Myaccount/booking">
                      <ButtonPrimary className=" w-full my-5 py-2">
                        View Order Detail
                      </ButtonPrimary>
                    </Link>
                  </div> 
                  )
                }
                <div className="">
                  <Link
                    to="/"
                    className="px-9 py-[8px] text-[#999] my-4 hover:underline rounded-md"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <span>
                        <IoMdArrowBack />
                      </span>
                      <span> Back to Home</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </React.Fragment>
  );
};

export default Layout(PayDone);
